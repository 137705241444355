.card {
    max-width: 434px;
    max-height: 254px;
    width: 100%;
    height: 100%;
    perspective: 1000px; /* Это свойство создаёт 3D-пространство для анимации */
  }
  
  .card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .card:hover .card-inner {
    transform: rotateX(180deg); /* Поворачиваем карточку по оси X */
  }
  
  .card-front,
  .card-back {
    max-width: 434px;
    max-height: 254px;
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #fff;
  }
  
  .card-front {

  }
  
  .card-back {

    transform: rotateX(180deg); /* Поворачиваем заднюю сторону карточки на 180 градусов */
  }